import React from "react";
import Featured from "../../Components/Featured";
import Slider from "../../Components/Slider";

const Home = () => {
  return (
    <>
      <Slider />
      <Featured />
    </>
  );
};

export default Home;
